@import '~@77sol-ui/atoms/dist/index.css';
@import '~@77sol/styles/dist/index.css';
@import './styles/skeletons.css';

body,
html,
* {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  height: var(--app-height);
  background-color: #f7f7fc;
}

.link {
  text-decoration: underline;
}
.link:hover {
  color: #2D69F5
}

svg {
  height: auto;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

*::-webkit-scrollbar-corner,
*::-webkit-scrollbar-button {
  display: none;
}

@keyframes pulse-nav-icon {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 184, 5, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}